<script lang="ts" setup>
import { computed } from 'vue'
import type { Person, Entity } from '@prisma/client'

import { type SelectorItem } from '@/composables/selector'

import DIcon from '@/components/DIcon.vue'
import DAvatar from '@/components/DAvatar.vue'
import DButton, { type DButtonType } from '@/components/DButton.vue'
import DSelectorGeneric from '@/components/DSelectorGeneric.vue'
import DCommsMenu from '@/components/DCommsMenu.vue'

const NOT_SELECTED = 'not-selected'

const props = withDefaults(
    defineProps<{
        modelValue?: string
        people?: Pick<Person, 'id' | 'auth0Id' | 'name' | 'photoUrl' | 'email'>[]
        placeholder?: string
        notSelectedLabel?: string
        filterPlaceholder?: string
        buttonType?: DButtonType
        testid?: string
        withCommsMenu?: boolean
        entity?: Pick<Entity, 'label' | 'id'>
    }>(),
    {
        modelValue: NOT_SELECTED,
        buttonType: 'ghost',
        testid: 'd-selector-person',
    }
)
const emit = defineEmits<{
    (name: 'update:modelValue', value?: string): void
    (name: 'select', value: string): void
    (name: 'unselect'): void
}>()

const options = computed(() => {
    const mappedItems =
        props.people?.map((person) => ({
            id: person.id,
            label: person.name,
            auth0Id: person.auth0Id,
            name: person.name,
            email: person.email,
            avatar: {
                id: person.id,
                photoUrl: person.photoUrl,
            },
        })) ?? []
    return mappedItems
})

const showPlaceholder = computed(
    () => !!props.placeholder && (props.modelValue === NOT_SELECTED || selectedUserNotPartOfOptions.value)
)
const selected = computed(() => options.value.filter(({ id }) => id === props.modelValue))

const selectedUserNotPartOfOptions = computed(() => selected.value.length === 0)

function onModelValueUpdate(items: SelectorItem[]) {
    emit('update:modelValue', items[0]?.id === NOT_SELECTED ? undefined : items[0]?.id)
}

const shouldAddCommsMenu = computed(() => {
    return props.withCommsMenu && selected.value?.length
})
</script>

<template>
    <div :class="{ 'flex justify-between items-center overflow-hidden': shouldAddCommsMenu }">
        <div :class="{ 'flex-1 w-10': shouldAddCommsMenu }">
            <DSelectorGeneric
                :model-value="selected"
                :options="options"
                max-width="sm"
                :unselect-item-label="notSelectedLabel"
                unselect-item-icon="circle"
                :filter-placeholder="filterPlaceholder"
                :no-items-label="$t('components.SelectorItem.noPersonsFound')"
                :testid
                @select-item="emit('select', $event)"
                @unselect-item="emit('unselect')"
                @update:model-value="onModelValueUpdate"
            >
                <slot>
                    <DButton :type="buttonType ?? 'ghost'" :data-testid="testid + '-button'">
                        <template v-if="showPlaceholder">
                            <DIcon icon="circle" class="text-slate-500" size="md" />
                            <span class="truncate">{{ placeholder }}</span>
                        </template>
                        <template v-else>
                            <span
                                v-tooltip="{
                                    value: selected[0]?.label,
                                }"
                                class="flex items-center gap-1 truncate"
                            >
                                <DAvatar v-if="selected[0]?.avatar?.id" :users="[selected[0]?.avatar]" size="xs" />
                                <span class="truncate">{{ selected[0]?.label }}</span>
                            </span>
                        </template>
                    </DButton>
                </slot>
            </DSelectorGeneric>
        </div>
        <DCommsMenu v-if="shouldAddCommsMenu && entity" :people="selected" :entity />
    </div>
</template>
