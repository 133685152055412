import { createRouter, createWebHistory, type RouteLocationNormalized, type Router } from 'vue-router'
import { type App } from 'vue'

import { PhaseCategorySetEnum, EntityCoreTypeEnum } from '@prisma/client'
import { checkForOnboarding } from '@/middleware/checkForOnboarding'
import { handleAuth0CallbackErrors } from '@/middleware/handleAuth0Callback'
import { getAuthGuard } from '@/middleware/getAuthGuard'
import { roleGuard } from '@/middleware/roleGuard'
import { featureToggleGuard } from '@/middleware/featureToggleGuard'
import { handleMissingFilesErrors } from '@/middleware/handleMissingFilesErrors'
import { getEntityRoutePath } from '@/middleware/getEntityRoutePath'
import { useAuthStore } from '@/stores/authStore'
import type { Layouts } from '@/layouts'
import { FeatureToggle } from '@mindfuel/server/src/common/config'
import { EntityViewPageNameEnum } from '@mindfuel/server/src/common/filters'
import { client } from '@/plugins/trpc'

declare module 'vue-router' {
    interface RouteMeta {
        requiresAuth?: boolean
        roles?: ('admin' | 'owner')[]
        featureToggles?: FeatureToggle[]
        noFeatureToggleRedirectRoute?: RouteLocationRaw
        layout?: keyof Layouts
        /** used for settings side navigation to hide a route */
        hidden?: boolean
    }
}

let hasInitializedDynamicRoutes = false

const routerPlugin = (app: App) => {
    hasInitializedDynamicRoutes = false

    const router = create()

    // this handles auth0 callback errors
    router.beforeEach(handleAuth0CallbackErrors)

    // this checks if the user is authenticated and redirects to the login page if not
    // after this check user context is available in the store
    router.beforeEach(getAuthGuard(app))

    // this checks if the user has the required roles to access the route
    // you need all the roles of the route to access it
    // roles are inherited from parent routes
    router.beforeEach(roleGuard)

    // this checks if the user has the required feature toggles to access the route
    // you need all the feature toggles of the route to access it
    // feature toggles are inherited from parent routes
    router.beforeEach(featureToggleGuard)

    // this handles missing files errors
    // happens mostly when releasing new versions and the user has the old version in browser
    router.onError(handleMissingFilesErrors)

    // this adds custom entity routes to the router after the user is authenticated
    // problem is that auth0 needs to be initialized after we initialize the router
    // but then we cannot fetch the entities before the router is initialized
    router.beforeEach(async (to, from, next) => {
        const authStore = useAuthStore()

        if (!authStore.isAuthenticated || authStore.isLoading || hasInitializedDynamicRoutes) return next()

        await addEntityRoutes(router)
        hasInitializedDynamicRoutes = true
        return next(to.fullPath)
    })

    return router
}

function create() {
    return createRouter({
        history: createWebHistory(import.meta.env.BASE_URL),
        scrollBehavior(to) {
            if (to.hash) {
                // wait 300ms for the page to load before scrolling / @TODO Bugfix in ONE-515
                setTimeout(() => {
                    document.getElementById(to.hash.substring(1))?.scrollIntoView({ behavior: 'smooth' })
                }, 300)
            }
            return { top: 0 }
        },
        routes: [
            {
                path: '/',
                // Note: Guards are not executed on redirected routes.
                redirect: (to: RouteLocationNormalized) => {
                    return { name: 'welcome', query: to.query }
                },
            },
            {
                path: '/login',
                component: { template: '' },
                beforeEnter: async (to) => {
                    const authStore = useAuthStore()

                    // Handle invitation link from email
                    if (to.query.invitation) {
                        const org = (to.query.currentOrganization || to.query.organization) as string | undefined
                        await authStore.login({
                            authorizationParams: {
                                invitation: to.query.invitation as string,
                                organization: org,
                                max_age: 604800, // 1 week
                            },
                        })
                        return false
                    }

                    // We have to be careful when adding email verification since that would also be handled in this route:
                    // https://auth0.com/docs/authenticate/login/auth0-universal-login/configure-default-login-routes
                    await authStore.logout()
                    return false
                },
            },
            {
                path: '/logout',
                meta: { layout: 'Empty', requiresAuth: false },
                beforeEnter: async () => {
                    const authStore = useAuthStore()
                    /* Note: The logout method will redirect the user back to the login.
                            Redirect kept here not to break e2e tests.
                    */
                    return await authStore.logout()
                },
                component: { template: '' },
            },
            {
                path: '/welcome',
                redirect: { name: 'welcome-info' },
                name: 'welcome',
                meta: { layout: 'Empty', requiresAuth: true },
                beforeEnter: [checkForOnboarding],
                children: [
                    {
                        path: 'info',
                        name: 'welcome-info',
                        component: () => import('@/modules/welcome/views/welcome-info.vue'),
                    },
                    {
                        path: 'profile',
                        name: 'welcome-profile',
                        component: () => import('@/modules/welcome/views/welcome-profile.vue'),
                    },
                    {
                        path: 'goals',
                        name: 'welcome-goals',
                        component: () => import('@/modules/welcome/views/welcome-goals.vue'),
                    },
                ],
            },
            {
                path: '/inbox',
                name: 'inbox',
                meta: { layout: 'DefaultV2', requiresAuth: true },
                components: {
                    default: () => import('../modules/inbox/views/inbox-content.vue'),
                    header: () => import('../modules/inbox/views/inbox-header.vue'),
                },
            },

            // mission-control

            {
                path: '/mission-control',
                name: 'mission-control',
                meta: {
                    layout: 'DefaultV2',
                    requiresAuth: true,
                    featureToggles: [FeatureToggle.missionControl],
                },
                components: {
                    default: () => import('../modules/mission-control/views/MissionControl.vue'),
                    header: () => import('../modules/mission-control/views/MissionControlHeader.vue'),
                },
            },
            {
                path: '/mission-control/opportunity-report',
                name: 'opportunity-report',
                meta: {
                    layout: 'DefaultList',
                    requiresAuth: true,
                    featureToggles: [FeatureToggle.missionControl],
                },
                components: {
                    default: () => import('../modules/mission-control/views/OpportunityReport.vue'),
                },
            },
            {
                path: '/mission-control/data-product-report',
                name: 'data-product-report',
                meta: {
                    layout: 'DefaultList',
                    requiresAuth: true,
                    featureToggles: [FeatureToggle.missionControl],
                },
                components: {
                    default: () => import('../modules/mission-control/views/DataProductReport.vue'),
                },
            },

            {
                path: '/entity/:id/:tab?',
                name: 'entity-details',
                meta: { layout: 'Empty', requiresAuth: true },
                beforeEnter: getEntityRoutePath,
                component: { template: '' },
            },

            // business-lineage

            {
                path: '/business-lineage/:id',
                name: 'business-lineage-full-screen',
                meta: { layout: 'Empty', requiresAuth: true },
                props: (route) => ({ id: route.params.id }),
                component: () => import('../modules/business-lineage/business-lineage.vue'),
            },

            // saved views
            {
                path: '/my-views',
                name: 'saved-views-overview',
                meta: { layout: 'DefaultList', requiresAuth: true },
                component: () => import('../modules/saved-view/views/SavedViewsOverview.vue'),
            },

            {
                path: `/my-views/:id`,
                name: 'saved-view-details',
                meta: { layout: 'DefaultList', requiresAuth: true },
                props: (route) => ({ id: route.params.id }),
                component: () => import('../modules/saved-view/views/SavedViewDetails.vue'),
            },

            // profile

            {
                path: '/profile',
                meta: { layout: 'Profile', requiresAuth: true },
                redirect: { name: 'userProfile.section.personalInformation' },
                children: [
                    {
                        path: 'personal-info',
                        name: 'userProfile.section.personalInformation',
                        component: () => import('@/modules/auth/views/PersonalInfoView.vue'),
                    },
                    {
                        path: 'preferences',
                        name: 'userProfile.section.preferences',
                        component: () => import('@/modules/auth/views/PreferencesView.vue'),
                    },
                    {
                        path: 'activity-log',
                        name: 'userProfile.section.activityLog',
                        component: () => import('@/modules/auth/views/ActivityLogView.vue'),
                    },
                    {
                        name: 'userProfile.section.terms',
                        path: 'user/terms',
                        redirect: { name: 'terms-and-conditions' },
                    },
                    {
                        name: 'userProfile.section.privacy',
                        path: 'user/privacy',
                        redirect: { name: 'privacy-policy' },
                    },
                ],
            },

            // Please use the following format for all settings routes:
            // /settings/section/<section>/<subsection>
            // For example:
            // /settings/section/organization
            // /settings/section/organization/users
            // the settings navigation will be rendered based on those routes
            {
                path: '/settings',
                meta: { layout: 'Settings', roles: ['admin'], requiresAuth: true },
                redirect: { name: 'settings.section.workspace.users' },
                children: [
                    // Workspace
                    {
                        path: 'workspace',
                        name: 'settings.section.workspace',
                        redirect: { name: 'settings.section.workspace.organization-name' },
                        children: [
                            {
                                path: 'organization-name',
                                name: 'settings.section.workspace.organization-name',
                                component: () => import('@/modules/settings/views/workspace/OrganizationName.vue'),
                            },
                            {
                                path: 'import',
                                name: 'settings.section.workspace.import',
                                redirect: { name: 'settings.section.workspace.import.opportunity' },
                                component: () =>
                                    import(
                                        '@/modules/settings/views/workspace/portfolio-import/SettingsPortfolioImport.vue'
                                    ),
                                children: [
                                    {
                                        path: 'opportunity',
                                        name: 'settings.section.workspace.import.opportunity',
                                        component: () =>
                                            import(
                                                '@/modules/settings/views/workspace/portfolio-import/SettingsPortfolioImportOpportunity.vue'
                                            ),
                                    },
                                    {
                                        path: 'data-product',
                                        name: 'settings.section.workspace.import.dataProduct',
                                        component: () =>
                                            import(
                                                '@/modules/settings/views/workspace/portfolio-import/SettingsPortfolioImportDataProduct.vue'
                                            ),
                                    },
                                ],
                            },
                            {
                                path: 'connectors',
                                name: 'connectors',
                                meta: { featureToggles: [FeatureToggle.settingsConnectorsDemo] },
                                component: () =>
                                    import('@/modules/settings/connectors-sales-demo/views/SettingsConnectorsList.vue'),
                            },
                            {
                                path: 'currency',
                                name: 'settings.section.workspace.currency',
                                component: () => import('@/modules/settings/views/workspace/SettingsCurrency.vue'),
                            },
                            {
                                path: 'users',
                                name: 'settings.section.workspace.users',
                                component: () => import('@/modules/settings/views/workspace/users/SettingsUsers.vue'),
                                redirect: { name: 'settings.section.workspace.users.active-users' },
                                children: [
                                    {
                                        path: 'active-users',
                                        name: 'settings.section.workspace.users.active-users',
                                        component: () =>
                                            import(
                                                '@/modules/settings/views/workspace/users/SettingsUsersActiveList.vue'
                                            ),
                                        children: [
                                            {
                                                path: ':id',
                                                name: 'settings.section.workspace.users.active-users.details',
                                                component: () =>
                                                    import(
                                                        '@/modules/settings/views/workspace/users/SettingsUsersActiveDetails.vue'
                                                    ),
                                            },
                                        ],
                                    },
                                    {
                                        path: 'pending-invitations',
                                        name: 'settings.section.workspace.users.pending-invitations',
                                        component: () =>
                                            import(
                                                '@/modules/settings/views/workspace/users/SettingsUsersPendingList.vue'
                                            ),
                                    },
                                    {
                                        path: 'deactivated-users',
                                        name: 'settings.section.workspace.users.deactivated-users',
                                        component: () =>
                                            import(
                                                '@/modules/settings/views/workspace/users/SettingsUsersDeactivatedList.vue'
                                            ),
                                    },
                                ],
                            },
                            {
                                path: 'external-members',
                                name: 'settings.section.workspace.external-members',
                                redirect: { name: 'settings.section.workspace.external-members.list' },
                                children: [
                                    {
                                        path: '',
                                        name: 'settings.section.workspace.external-members.list',
                                        component: () =>
                                            import('@/modules/settings/views/workspace/ExternalMembers.vue'),
                                        children: [
                                            {
                                                path: ':id',
                                                name: 'settings.section.workspace.external-members.edit',
                                                component: () =>
                                                    import(
                                                        '@/modules/settings/views/workspace/ExternalMembersDetails.vue'
                                                    ),
                                            },
                                            {
                                                path: 'create',
                                                name: 'settings.section.workspace.external-members.create',
                                                component: () =>
                                                    import(
                                                        '@/modules/settings/views/workspace/ExternalMembersCreate.vue'
                                                    ),
                                            },
                                        ],
                                    },
                                ],
                            },
                            // AI Lumo
                            {
                                path: 'ai',
                                name: 'settings.section.workspace.ai',
                                meta: { featureToggles: [FeatureToggle.copilot] },
                                component: () => import('@/modules/settings/views/workspace/SettingsLumoAI.vue'),
                            },
                            {
                                path: 'keywords',
                                name: 'settings.section.workspace.keywords',
                                redirect: { name: 'settings.section.workspace.keywords.list' },
                                children: [
                                    {
                                        path: '',
                                        name: 'settings.section.workspace.keywords.list',
                                        component: () =>
                                            import('@/modules/settings/views/workspace/SettingsKeywords.vue'),
                                    },
                                ],
                            },
                        ],
                    },
                    // Organization
                    {
                        path: 'organization',
                        name: 'settings.section.organization',
                        redirect: { name: 'settings.section.organization.business-areas' },
                        children: [
                            {
                                path: 'business-areas',
                                name: 'settings.section.organization.business-areas',
                                redirect: { name: 'settings.section.organization.business-areas.list' },
                                children: [
                                    {
                                        path: '',
                                        name: 'settings.section.organization.business-areas.list',
                                        component: () =>
                                            import('@/modules/settings/views/organization/SettingsBusinessAreas.vue'),
                                    },
                                ],
                            },
                            {
                                path: 'teams',
                                name: 'settings.section.organization.teams',
                                redirect: { name: 'settings.section.organization.teams.list' },
                                children: [
                                    {
                                        path: '',
                                        name: 'settings.section.organization.teams.list',
                                        component: () => import('@/modules/settings/views/organization/TeamList.vue'),
                                    },
                                ],
                            },
                            {
                                path: 'business-goals',
                                name: 'settings.section.organization.business-goals',
                                redirect: { name: 'settings.section.organization.business-goals.list' },
                                children: [
                                    {
                                        path: '',
                                        name: 'settings.section.organization.business-goals.list',
                                        component: () =>
                                            import('@/modules/settings/views/organization/SettingsBusinessGoals.vue'),
                                    },
                                ],
                            },
                            {
                                path: 'metrics',
                                name: 'settings.section.organization.metrics',
                                redirect: { name: 'settings.section.organization.metrics.list' },
                                children: [
                                    {
                                        path: '',
                                        name: 'settings.section.organization.metrics.list',
                                        component: () =>
                                            import('@/modules/settings/views/opportunity/SettingsMetrics.vue'),
                                    },
                                ],
                            },
                        ],
                    },
                    // Items
                    {
                        path: 'items',
                        name: 'settings.section.items',
                        redirect: { name: 'settings.section.items.custom-properties' },
                        children: [
                            {
                                path: 'data-product-types',
                                name: 'settings.section.items.data-product-types',
                                component: () =>
                                    import(
                                        '@/modules/settings/views/items/data-product-types/DataProductTypesList.vue'
                                    ),
                                children: [
                                    {
                                        path: 'create',
                                        name: 'settings.section.items.data-product-types.create',
                                        component: () =>
                                            import(
                                                '@/modules/settings/views/items/data-product-types/DataProductTypeCreateOrEdit.vue'
                                            ),
                                    },
                                    {
                                        path: 'edit/:id',
                                        name: 'settings.section.items.data-product-types.edit',
                                        component: () =>
                                            import(
                                                '@/modules/settings/views/items/data-product-types/DataProductTypeCreateOrEdit.vue'
                                            ),
                                    },
                                ],
                            },
                            {
                                path: 'custom-properties',
                                name: 'settings.section.items.custom-properties',
                                redirect: { name: 'settings.section.items.custom-properties.list' },
                                children: [
                                    {
                                        path: '',
                                        name: 'settings.section.items.custom-properties.list',
                                        component: () =>
                                            import(
                                                '@/modules/settings/views/items/custom-properties/CustomPropertiesList.vue'
                                            ),
                                    },
                                    {
                                        path: 'create',
                                        name: 'settings.section.items.custom-properties.create',
                                        component: () =>
                                            import(
                                                '@/modules/settings/views/items/custom-properties/CustomPropertyCreateOrEdit.vue'
                                            ),
                                    },
                                    {
                                        path: 'edit/:id',
                                        name: 'settings.section.items.custom-properties.edit',
                                        component: () =>
                                            import(
                                                '@/modules/settings/views/items/custom-properties/CustomPropertyCreateOrEdit.vue'
                                            ),
                                    },
                                ],
                            },
                            {
                                path: 'segments',
                                name: 'settings.section.items.segments',
                                redirect: { name: 'settings.section.items.segments.list' },
                                children: [
                                    {
                                        path: '',
                                        name: 'settings.section.items.segments.list',
                                        component: () => import('@/modules/settings/views/items/SettingsSegments.vue'),
                                    },
                                ],
                            },
                            {
                                path: 'phases',
                                name: 'settings.section.items.phases',
                                component: () => import('@/modules/settings/views/items/PhasesSettings.vue'),
                                redirect: { name: 'settings.section.items.phases.opportunity' },
                                children: [
                                    {
                                        path: 'opportunity',
                                        name: 'settings.section.items.phases.opportunity',
                                        component: () => import('@/modules/settings/views/items/PhasesList.vue'),
                                        props: { categorySet: PhaseCategorySetEnum.OpportunityPhaseCategoryEnum },
                                    },
                                    {
                                        path: 'data-product',
                                        name: 'settings.section.items.phases.dataProduct',
                                        component: () => import('@/modules/settings/views/items/PhasesList.vue'),
                                        props: { categorySet: PhaseCategorySetEnum.ProductPhaseCategoryEnum },
                                    },
                                ],
                            },
                        ],
                    },
                    // Assessment
                    {
                        path: 'assessment',
                        name: 'settings.section.assessment',
                        redirect: { name: 'settings.section.assessment.value-criteria' },
                        children: [
                            {
                                path: 'value-criteria',
                                name: 'settings.section.assessment.value-criteria',
                                redirect: { name: 'settings.section.assessment.value-criteria.list' },
                                children: [
                                    {
                                        path: '',
                                        name: 'settings.section.assessment.value-criteria.list',
                                        component: () =>
                                            import('@/modules/settings/views/assessment/value-score/ValueScore.vue'),
                                    },
                                    {
                                        path: 'create',
                                        name: 'settings.section.assessment.value-criteria.create',
                                        component: () =>
                                            import(
                                                '@/modules/settings/views/assessment/value-score/ValueScoreCriteriaCreateOrEdit.vue'
                                            ),
                                    },
                                    {
                                        path: 'edit/:id',
                                        name: 'settings.section.assessment.value-criteria.edit',
                                        props: (route) => ({ id: route.params.id }),
                                        component: () =>
                                            import(
                                                '@/modules/settings/views/assessment/value-score/ValueScoreCriteriaCreateOrEdit.vue'
                                            ),
                                    },
                                ],
                            },
                            {
                                path: 'data-products',
                                name: 'settings.section.assessment.data-products',
                                redirect: { name: 'settings.section.assessment.data-products.list' },
                                children: [
                                    {
                                        path: '',
                                        name: 'settings.section.assessment.data-products.list',
                                        component: () =>
                                            import(
                                                '@/modules/settings/views/assessment/data-product/DataProductAssessments.vue'
                                            ),
                                    },
                                    {
                                        path: 'create',
                                        name: 'settings.section.assessment.data-products.create',
                                        component: () =>
                                            import(
                                                '@/modules/settings/views/assessment/data-product/DataProductAssessmentCreateOrEdit.vue'
                                            ),
                                    },
                                    {
                                        path: 'edit/:id',
                                        name: 'settings.section.assessment.data-products.edit',
                                        component: () =>
                                            import(
                                                '@/modules/settings/views/assessment/data-product/DataProductAssessmentCreateOrEdit.vue'
                                            ),
                                    },
                                ],
                            },
                        ],
                    },

                    // Management
                    {
                        path: 'management',
                        name: 'settings.section.management',
                        meta: { layout: 'Settings', roles: ['owner'] },
                        children: [
                            {
                                path: 'organizations',
                                name: 'settings.section.management.organizations',
                                redirect: { name: 'settings.section.management.organizations.list' },
                                children: [
                                    {
                                        path: '',
                                        name: 'settings.section.management.organizations.list',
                                        component: () =>
                                            import('@/modules/settings/views/management/OrganizationsList.vue'),
                                    },
                                    {
                                        path: 'create',
                                        name: 'settings.section.management.organizations.create',
                                        meta: { layout: 'Settings' },
                                        component: () =>
                                            import('@/modules/settings/views/management/OrganizationCreate.vue'),
                                    },
                                    {
                                        path: ':id',
                                        name: 'settings.section.management.organizations.details',
                                        component: () =>
                                            import('@/modules/settings/views/management/OrganizationDetails.vue'),
                                        redirect: {
                                            name: 'settings.section.management.organizations.details.active-users',
                                        },
                                        children: [
                                            {
                                                path: 'active-users',
                                                name: 'settings.section.management.organizations.details.active-users',
                                                component: () =>
                                                    import('@/modules/settings/views/management/ActiveUsersList.vue'),
                                            },
                                            {
                                                path: 'pending-invitations',
                                                name: 'settings.section.management.organizations.details.pending-invitations',
                                                component: () =>
                                                    import(
                                                        '@/modules/settings/views/management/PendingInvitationsList.vue'
                                                    ),
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                path: 'demo-organizations',
                                name: 'settings.section.management.demoOrganizations',
                                component: () =>
                                    import('@/modules/settings/views/management/DemoOrganizationsView.vue'),
                            },
                        ],
                    },
                ],
            },

            // 403 Forbidden
            {
                path: '/access-denied',
                name: 'access-denied',
                meta: { layout: 'Empty' },
                component: () => import('@/modules/auth/views/AccessDeniedView.vue'),
            },

            // 404 Item not found
            {
                path: '/item-not-found',
                name: 'item-not-found',
                meta: { layout: 'Empty' },
                component: () => import('@/views/ItemNotFound.vue'),
            },

            // 404 Page Not Found
            {
                path: '/:pathMatch(.+)',
                name: 'page-not-found',
                meta: { layout: 'Empty' },
                beforeEnter: (to) => {
                    // Only when dynamic routes have been initialized, we can be certain that something does not exist.
                    // Otherwise we go to /authenticate/* to force authentication and dynamic routes initialization.
                    if (hasInitializedDynamicRoutes) return true
                    return { path: `/authenticate${to.fullPath}`, query: to.query }
                },
                component: () => import('@/views/PageNotFound.vue'),
            },

            {
                path: '/authenticate/:pathMatch(.+)',
                name: 'authenticate',
                meta: { layout: 'Empty', requiresAuth: true },
                beforeEnter: (to) => ({ path: '/' + to.params.pathMatch, query: to.query }),
                component: { template: '' },
            },

            // 500 Internal Server Error
            {
                path: '/internal-server-error',
                name: 'internal-server-error',
                meta: { layout: 'Empty' },
                component: () => import('@/views/InternalServerError.vue'),
            },

            {
                path: '/no-organization',
                name: 'no-organization-error',
                meta: { layout: 'Empty' },
                component: () => import('@/modules/auth/views/NoOrganizationView.vue'),
            },
            {
                path: '/invitation-expired',
                name: 'invitation-expired',
                meta: { layout: 'Empty' },
                component: () => import('@/modules/auth/views/InvitationExpiredView.vue'),
            },
            {
                path: '/account-linking-completed',
                name: 'account-linking-completed',
                meta: { layout: 'Empty' },
                component: () => import('@/modules/auth/views/AccountLinkingCompleted.vue'),
            },
            {
                path: '/sign-in-error',
                name: 'sign-in-error',
                meta: { layout: 'Empty' },
                component: () => import('@/modules/auth/views/SignInErrorView.vue'),
            },

            // Legal Pages
            {
                path: '/privacy-policy',
                name: 'privacy-policy',
                meta: { layout: 'Empty' },
                component: () => import('@/views/legal/PrivacyPolicy.vue'),
            },
            {
                path: '/terms',
                name: 'terms-and-conditions',
                meta: { layout: 'Empty' },
                component: () => import('@/views/legal/TermsAndConditions.vue'),
            },
        ],
    })
}

async function addEntityRoutes(router: Router) {
    const entityTypes = await client.entityType.getAll.query()

    router.addRoute({
        path: '/data-product',
        name: 'data-product-list-all',
        meta: { layout: 'DefaultList', requiresAuth: true },
        props: () => ({
            filterPageName: EntityViewPageNameEnum.dataProductList,
        }),
        component: () => import('../modules/data-product/overview/views/data-product-overview.vue'),
    })

    entityTypes.forEach((entityType) => {
        if (entityType.type === EntityCoreTypeEnum.Opportunity) {
            router.addRoute({
                path: `/${entityType.routePath}`,
                name: `${entityType.routePath}-list`,
                meta: { layout: 'DefaultList', requiresAuth: true },
                component: () => import('../modules/opportunity/overview/views/opportunity-overview.vue'),
            })

            router.addRoute({
                path: `/${entityType.routePath}/:id`,
                name: `${entityType.routePath}-details`,
                redirect: { name: `tab-${entityType.abbreviation}-demand` },
                meta: { layout: 'Details', requiresAuth: true },
                props: {
                    default: (route) => ({ id: route.params.id }),
                    header: (route) => ({ id: route.params.id }),
                    drawer: (route) => ({ id: route.params.id }),
                },
                components: {
                    default: () => import('../modules/opportunity/details/views/opportunity-details.vue'),
                    header: () => import('../modules/opportunity/details/views/opportunity-header.vue'),
                    drawer: () => import('../modules/opportunity/components/OpportunityDrawer.vue'),
                },
                children: [
                    {
                        path: 'demand',
                        name: `tab-${entityType.abbreviation}-demand`,
                        props: (route) => ({ id: route.params.id }),
                        component: () => import('../modules/opportunity/details/views/tabs/opportunity-demand.vue'),
                    },
                    {
                        path: 'value',
                        name: `tab-${entityType.abbreviation}-value-qualification`,
                        props: (route) => ({ id: route.params.id }),
                        component: () =>
                            import('../modules/opportunity/details/views/tabs/opportunity-value-qualification.vue'),
                    },
                    {
                        path: 'strategy',
                        name: `tab-${entityType.abbreviation}-strategy`,
                        props: (route) => ({ id: route.params.id }),
                        component: () => import('../modules/opportunity/details/views/tabs/opportunity-strategy.vue'),
                    },
                    {
                        path: 'solution',
                        name: `tab-${entityType.abbreviation}-solution-ideas`,
                        props: (route) => ({ id: route.params.id }),
                        component: () =>
                            import('../modules/opportunity/details/views/tabs/opportunity-solution-design.vue'),
                    },
                ],
            })
        } else {
            router.addRoute({
                path: `/data-product/${entityType.routePath}`,
                name: `${entityType.routePath}-list`,
                meta: { layout: 'DefaultList', requiresAuth: true },
                props: () => ({
                    entityType: entityType,
                    filterPageName: `${entityType.routePath}-list`,
                }),
                component: () => import('../modules/data-product/overview/views/data-product-overview.vue'),
            })

            router.addRoute({
                path: `/data-product/${entityType.routePath}/:id`,
                name: `${entityType.routePath}-details`,
                redirect: { name: `tab-${entityType.abbreviation}-documentation` },
                meta: { layout: 'Details', requiresAuth: true },
                props: {
                    default: (route) => ({ id: route.params.id, type: entityType }),
                    header: (route) => ({ id: route.params.id, type: entityType }),
                    drawer: (route) => ({ id: route.params.id, type: entityType }),
                },
                components: {
                    default: () => import('../modules/data-product/details/views/data-product-details.vue'),
                    header: () => import('../modules/data-product/details/views/data-product-header.vue'),
                    drawer: () => import('../modules/data-product/components/DataProductDrawer.vue'),
                },
                children: [
                    {
                        path: 'documentation',
                        name: `tab-${entityType.abbreviation}-documentation`,
                        props: (route) => ({ id: route.params.id }),
                        component: () =>
                            import('../modules/data-product/details/views/tabs/data-product-documentation.vue'),
                    },
                    {
                        path: 'opportunities',
                        name: `tab-${entityType.abbreviation}-opportunities`,
                        props: (route) => ({ id: route.params.id }),
                        component: () =>
                            import('../modules/data-product/details/views/tabs/data-product-opportunities.vue'),
                    },
                    {
                        path: 'connected-products',
                        name: `tab-${entityType.abbreviation}-connected-products`,
                        props: (route) => ({ id: route.params.id }),
                        component: () =>
                            import('../modules/data-product/details/views/tabs/data-product-connected-products.vue'),
                    },
                    {
                        path: 'assessments',
                        name: `tab-${entityType.abbreviation}-assessments`,
                        props: (route) => ({ id: route.params.id }),
                        component: () =>
                            import('../modules/data-product/details/views/tabs/data-product-assessment.vue'),
                    },
                ],
            })
        }
    })
}

export default routerPlugin
